<template>
  <div class="psyc-container">
    <header-bar>
      <svg-icon
        @click="$router.back(-1)"
        icon-class="back"
        width="30px"
        height="30px"
      />
      <span class="header-title">线上精品课程 </span>
    </header-bar>
    <div class="courses-content">
      <div
        v-for="item in courseList"
        :key="item.index"
        class="course_item"
        @click="handleToLink(item.url)"
      >
        <div
          class="courseImg"
          :style="{ backgroundImage: `url(${item.img})` }"
        ></div>
        <p class="courseTitle">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/views/pc/components/header.vue";

import { excellentCourseList } from "../data";
export default {
  components: {
    HeaderBar,
  },
  data() {
    return {
      courseList: excellentCourseList,
    };
  },
  mounted() {},
  methods: {
    handleToLink(url) {
      window.open(url);
    },
    // handleScroll(item) {
    //   this.activeTab = item.label;
    //   document.documentElement.scrollTop = item.scrollTop;
    // },
  },
};
</script>

<style lang="scss" scoped>
$vw_base: 1920;
// $vh_base: 1080;
@function vw($px) {
  //这个是移动端适配用到的
  @return ($px / $vw_base) * 100vw;
}
.psyc-container {
  display: flex;
  justify-content: center;
  .header-wrapper {
    position: fixed;
    top: 0;
    z-index: 9999;
  }
  .header-title {
    font-size: 26px;
    color: #fff;

    .svg-icon {
      cursor: pointer;
    }
  }
  .courses-content {
    width: vw(1068);
    margin-top: 160px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 20px;
    .course_item {
      width: 250px;
      background-color: #fff;
      border: 1px solid #ebeeef;
      border-radius: 5px;
      margin-top: 20px;
      margin-left: vw(20);
      cursor: pointer;
      .courseImg {
        height: 150px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .courseTitle {
        height: 60px;
        padding: 0 20px;
        line-height: 60px;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .course_item:nth-child(4n + 1) {
      margin-left: vw(0);
    }
  }
}
</style>
